<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> > Payments Approval History</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,8.01c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Zm0,6c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM20,0H4C1.79,0,0,1.8,0,4.01v1C0,7.21,1.79,9.01,4,9.01v10c0,2.76,2.24,5,5,5h6c2.76,0,5-2.24,5-5V9.01c2.21,0,4-1.79,4-4v-1C24,1.8,22.21,0,20,0Zm2,5c0,1.1-.9,2-2,2v-2c0-.55-.45-1-1-1s-1,.45-1,1v14c0,1.65-1.35,3-3,3h-6c-1.65,0-3-1.35-3-3V5.01c0-.55-.45-1-1-1s-1,.45-1,1v2c-1.1,0-2-.9-2-2v-1c0-1.1,.9-2,2-2H20c1.1,0,2,.9,2,2v1Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M21,0H3C1.35,0,0,1.35,0,3v6H4v15H20V9h4V3c0-1.65-1.35-3-3-3Zm-1,7v-3h-2V22H6V4h-2v3H2V3c0-.55,.45-1,1-1H21c.55,0,1,.45,1,1V7h-2Zm-8,1c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Zm0,6c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600"> {{ requestData.length }} Payments</p>
            </div>
            <!-- {{ requestData }} -->
              <!-- The pending activity approvals -->
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 sm:gap-y-4">
                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>

                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs pointer-events-none" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Employee</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Payment Method</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Attachments</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Spreadsheet Rows</td>
                        <td class="py-3 px-2 border text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in filteredRows" :key="i" class="text-gray-500 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.date }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.firstname }} {{ data.surname }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.payment_method_name }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.attachments.length }}</td>
                        <td v-if="data.sheet === null" class="py-3 px-2 border text-left"></td>
                        <td v-else class="py-3 px-2 border text-left">{{ data.sheet.length }}</td>
                        <td class="py-3 px-2 border text-center">
                          <button @click="trackPayment(data.id)" class="bg-emerald-600 shadow py-1 px-4 text-white hover:bg-emerald-700">Track</button>
                          <button @click="viewPayment(data.id)" class="bg-emerald-600 shadow py-1 px-4 text-white hover:bg-emerald-700">View</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if="(startRow+rowsPerPage) < requestData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry } from 'rxjs'

export default {
  computed: {
    ...mapState([
      'employee'
    ]),
    filteredRows () {
      return this.requestData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar
  },
  setup () {
    return {
    }
  },
  name: 'liquidation-approval-history',
  props: {
  },
  data: () => {
    return {
      filter: '',
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      showRequests: false,
      expandBody: false,
      requestData: [],
      BASE_URL: process.env.VUE_APP_BASE_URL,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 300)
    this.$store.commit('mutateSelectedSubMenuItem', 306)
    // getting the pending activity approvals
    this.getPayments()
  },
  methods: {
    trackPayment (id) {
      localStorage.setItem('paymentId', id)
      this.$router.push('/employee-track-payment')
    },
    viewPayment (id) {
      localStorage.setItem('paymentId', id)
      this.$router.push('/employee-view-payment')
    },
    // moves the pages of the pagenation
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.requestData.length) {
        this.startRow = newStartRow
      }
    },
    viewLiquidation (id) {
      localStorage.setItem('liquidationId', id)
      this.$router.push('/admin-view-liquidation')
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the pending activity approvals
    getPayments () {
      this.showRequests = false
      const observable = ajax({
        url: this.BASE_URL + `/payment/findByApproverId?id=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          console.log(response)
          const data = response.response
          this.requestData = data
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
